module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["hr","en","de"],"defaultLanguage":"hr","redirect":false,"redirectComponent":"/opt/build/repo/src/components/redirect.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Belgijski Ovčar - Uzgajivačnica Belgijskih Ovčara \"Od Slunja\"","short_name":"Belgijski Ovčar","description":"Uzgoj, savjeti i dresura Belgijskih Ovčara Malinoa (Malinois). Trenirani pod vodstvom bivšeg zapovjednika voda zaštitno-tragačih pasa Nikole Paulića","background_color":"#fff","theme_color":"#fff","lang":"hr","start_url":"/hr/","display":"standalone","icon":"/opt/build/repo/static/site/favicon.png","icon_options":{"purpose":"maskable"},"localize":[{"start_url":"/en/","lang":"en","name":"Belgian Shepard - Kennels \"Od Slunja\", Malinois training","short_name":"Belgian Shepard","description":"Breeding, tips and training of Belgian Shepherds Malinois. Trained under the leadership of former platoon commander of protective-search dogs Nikola Paulić"},{"start_url":"/de/","lang":"de","name":"Belgischen Schäferhunde - Zwinger \"Od Slunja\", Malinois Ausbildung","short_name":"Belgischen Schäferhunde","description":"Zucht, Tipps und Training der Belgischen Schäferhunde Malinois. Ausbildung unter der Leitung des ehemaligen Zugführers der Schutzhunde Nikola Paulić"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a3bb895b9f38cb9c2a55b83c19f76533"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
